import React, { Component } from 'react';
import Welcome from './welcome';
import EmailInput from './emailinput';
import NewUserDetailsInput from './newuserdetails';
import { Block } from 'baseui/block';
import { setUserData } from '../reducer/user';
import { connect } from 'react-redux';
import ExistingUserDetailsInput from './existinguserdetails';
import { v5 as uuidv5 } from 'uuid';
import { getUserById } from '../services/userservice'; // Import your getUser service
import { useNavigate } from 'react-router-dom';

const NAMESPACE = '6ba7b810-9dad-11d1-80b4-00c04fd430c8'; // Replace with a unique namespace

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSubmitted: false,
      userDetailsSubmitted: false,
      emailIdState: '',
      fadeIn: false,
      otpVerified: false,
    };
  }

  componentDidMount() {
    this.triggerFadeIn();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.otpVerified && !prevState.otpVerified) {
      this.fetchUserDetails();
    }

    // Redirect to /usersettings if isLoggedIn becomes true
    if (this.props.isLoggedIn && !prevProps.isLoggedIn) {
      this.props.navigate('/usersettings');  // Redirect to usersettings when isLoggedIn is true
    }
  }

  fetchUserDetails = async () => {
    const { emailIdProp, setUserData } = this.props;
    const emailId = emailIdProp || this.state.emailIdState;
    
    if (emailId) {
      const userId = uuidv5(emailId, NAMESPACE);
      try {
        const userData = await getUserById(userId); // Fetch user details by ID
        console.log("Fetched user data:", userData);
        
        if (userData) {
          setUserData(userData); // Dispatch the fetched data to Redux
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    } else {
      console.error('Email ID is missing');
    }
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  handleEmailSubmit = (emailId) => {
    this.setState({ emailSubmitted: true, emailIdState: emailId }, this.triggerFadeIn);
  };

  handleUserDetailsSubmit = () => {
    this.setState({ userDetailsSubmitted: true });
  };

  handleOtpVerified = (otpVerified) => {
    this.setState({ otpVerified });
  };

  getFirstName = (fullName) => {
    return fullName ? fullName.split(' ')[0] : '';
  }

  render() {
    const { fadeIn, otpVerified } = this.state;
    const { setUserData, isLoggedIn, emailIdProp, fullName } = this.props;
    const emailId = emailIdProp || this.state.emailIdState;

    const blockStyle = {
      width: '100%',
      paddingLeft: '0px',
      opacity: fadeIn ? 1 : 0,
      transition: 'opacity 1s ease-in',
      color: 'white',
      marginTop: '40px',  // Add default margin at the top
      '@media (max-width: 600px)': {
        width: '100%',
        paddingLeft: '0px',
        marginTop: '30px', // Reduce margin for smaller screens
      },
      '@media (max-width: 400px)': {
        width: '100%',
        paddingLeft: '0px',
        marginTop: '20px', // Further reduce margin for very small screens
      },
    };

    // Define titleSx
    const titleSx = {
      fontSize: '34px', // Default font size for large screens
      // fontWeight: 'bold',
      color: 'white',
      maxWidth: '600px',
      margin: '0 auto',
      textAlign: 'center',
      marginTop: '20px', // Add some top margin for spacing

      // Responsive styling for smaller screens
      '@media (max-width: 600px)': {
        fontSize: '30px', // Smaller font for medium screens
        marginTop: '15px', // Adjust the margin for better spacing
      },
      '@media (max-width: 400px)': {
        fontSize: '23px', // Smaller font for small screens like iPhone SE
        marginTop: '10px', // Add more margin on smaller screens
      },
    };

    return (
      <>
        <Block
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          flexDirection="column"
          textAlign="center"
        >
          {!isLoggedIn && (
            <Block marginBottom="20px" style={blockStyle}>
              <Welcome text="Welcome to your AI dating assistant" sx={titleSx} />
            </Block>
          )} 
          {(isLoggedIn && fullName === '') && (
            <Block marginBottom="20px" style={blockStyle}>
              <Welcome text="Let's automate your Hinge right now..." sx={titleSx} />
            </Block>
          )}

          {isLoggedIn && fullName !== '' && (
            <Block marginBottom="20px" style={blockStyle}>
              <Welcome text={`Welcome back ${this.getFirstName(fullName)}...`} sx={titleSx} />
            </Block>
          )}
          {!otpVerified && !isLoggedIn && (
            <EmailInput 
              onEmailSubmitted={this.handleEmailSubmit} 
              onOtpVerified={this.handleOtpVerified}
            />
          )}
          {isLoggedIn && fullName === '' && (
            <NewUserDetailsInput 
              setUserData={setUserData} 
              onSubmit={this.handleUserDetailsSubmit} 
              emailId={emailId}
            />
          )}
          {isLoggedIn && fullName !== '' && (
            <ExistingUserDetailsInput 
              setUserData={setUserData} 
              onSubmit={this.handleUserDetailsSubmit} 
              emailId={emailId}
            />
          )}
        </Block>
      </>
    );
  }
}

// Wrap LoginPage with navigate
function LoginPageWithNavigate(props) {
  const navigate = useNavigate();
  return <LoginPage {...props} navigate={navigate} />;
}

// Map Redux state to props
const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  emailIdProp: state.user.userData ? state.user.userData.email : '',
  fullName: state.user.userData ? state.user.userData.name : '',
});

// Map Redux action to props
const mapDispatchToProps = {
  setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageWithNavigate);
