import {getApiBaseURL} from './util'

var apiBaseURL = "http://34.132.57.0:5001"; // Your backend API base URL

// Function to send OTP to the email with reCAPTCHA
export const sendOtp = async (email, captchaValue) => {
  try {

    apiBaseURL = getApiBaseURL()

    const response = await fetch(`${apiBaseURL}/sendOtp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, captcha: captchaValue }),  // Include captchaValue in request body
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to send OTP');
    }

    return data; // Return the OTP data
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
};

// Function to validate OTP and login, and store JWT
export const validateOtpAndLogin = async (email, otp) => {
  try {

    apiBaseURL = getApiBaseURL()
    
    const response = await fetch(`${apiBaseURL}/validateOtpAndLogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, otp }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to validate OTP');
    }

    // If the response contains a token, store it in localStorage
    if (data.token) {
        // Store the JWT in localStorage
      const expiresIn = 48 * 60 * 60 * 1000; // 48 hours in milliseconds
      const expirationTime = Date.now() + expiresIn;

      localStorage.setItem('authToken', data.token);
      localStorage.setItem('authTokenExpiration', expirationTime);
    }

    return data; // Return the success status
  } catch (error) {
    console.error('Error validating OTP:', error);
    throw error;
  }
};

// Function to retrieve the token from localStorage
export const getAuthToken = () => {
  return localStorage.getItem('authToken'); // Retrieve the token from localStorage
};

// Function to clear the token (for logout)
export const clearAuthToken = () => {
  localStorage.removeItem('authToken'); // Remove the token from localStorage
};
