import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getUserById } from '../services/userservice'; // Make sure the path is correct
import { updateUser } from '../services/userservice'; // Import the createUser service
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const textFieldSlotProps = (isValid) => ({
  inputLabel: {
    style: { color: isValid ? 'white' : '#FFA07A' }, // Light salmon for error text color
  },
  input: {
    style: {
      color: 'white',
    },
  },
});

const textFieldSx = (isValid) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on error
    },
    '&:hover fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on focus
    },
  },
  width: '100%',
  marginLeft: '10px',

  '@media (max-width: 600px)': {
    width: '95%',
    marginLeft: '10px',
  },
  '@media (max-width: 400px)': {
    width: '95%',
    marginLeft: '10px',
  },
});

const sliderSx = {
  color: 'white',
  '& .MuiSlider-thumb': {
    color: 'white',
  },
  '& .MuiSlider-track': {
    color: 'white',
  },
  '& .MuiSlider-rail': {
    color: 'gray',
  },
  width: '100%',
  marginLeft: '15px',

  '@media (max-width: 600px)': {
    width: '95%',
    marginLeft: '15px',
  },
  '@media (max-width: 400px)': {
    width: '95%',
    marginLeft: '15px',
  },
};

const buttonSx = {
    backgroundColor: 'black',
    borderRadius: '10px',
    border: '2.5px solid grey',
    color: 'white',
    width: '100%',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: 'gray',
    },
    '&:active': {
      backgroundColor: 'gray', // Ensures background is gray when active
      color: 'white', // Keeps text white on active state
    },
    '&:focus': {
      backgroundColor: 'black', // Keeps the button black on focus
      color: 'white', // Keeps text white on focus
      outline: 'none', // Removes the blue outline
    },
    
    '@media (max-width: 600px)': {
      width: '95%',
      marginLeft: '10px',
    },
    '@media (max-width: 400px)': {
      width: '95%',
      marginLeft: '10px',
    },
      // Override the disabled state to make the text color visible
    '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',  // Change the background color of the disabled button
        color: 'rgba(255, 255, 255, 0.5)', // Light grey color for disabled text to keep it visible
        borderColor: 'grey', // Keep the border color consistent
    },
  };

class ExistingUserDetailsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
          fullName: '',  // Make sure this is initialized to an empty string
          phoneNumber: '', // Ensure an empty string for phoneNumber
          likesPerDay: 100, // Default to a valid number for the slider
          aiResponsePercentage: 0, // Default to a valid number for the slider
          isValid: true,
          fadeIn: false,
          isPhoneValid: true,
          marginLeft: '10px',
          checkboxChecked: false, // Default to false for the checkbox
          shouldKeepProfileActive: true, // New state to handle shouldPause checkbox
          loading: false,
        };
      }
    
      async componentDidMount() {
        
        this.triggerFadeIn();
        
        window.addEventListener('resize', this.updateMarginLeft);
        
        this.updateMarginLeft();
    
        console.log(this.props.emailId);
    
        const authToken = localStorage.getItem('authToken');
        
        var userId = localStorage.getItem('userId');
    
        if (userId && authToken) {
          try {
            const userData = await getUserById(userId);
    
            if (userData) {
               this.props.setUserData(userData);
              // Ensure all fields are assigned correctly to avoid undefined values
              this.setState({
                fullName: userData.name || '',  // Provide default fallback as ''
                phoneNumber: userData.phoneNumber || '', // Ensure phoneNumber is not undefined
                likesPerDay: userData.sendMaxLikes || 100, // Fallback to 100 if undefined
                aiResponsePercentage: userData.sendMaxResponses || 0, // Fallback to 0 if undefined
                checkboxChecked: !!userData.isAcked,  // Ensure a boolean value
                id: userData.id,
                isFreeTrial: userData.isFreeTrial,
                deviceId: userData.deviceId,
                isAcked: userData.isAcked,
                isActive: userData.isActive,
                shouldKeepProfileActive: !userData.shouldPause,
                subscription: userData.subscription,  // Store subscription data in state
                isValid: true,
              });
            }
          } catch (error) {
            console.error('User not found or error fetching user:', error);
          }
        } else {
          console.error('Email ID is not provided');
        }
      }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMarginLeft);
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  updateMarginLeft = () => {
    const screenWidth = window.innerWidth;
    let marginLeft = '10px';

    if (screenWidth <= 600) {
      marginLeft = '10px';
    }
    if (screenWidth <= 400) {
      marginLeft = '10px';
    }

    this.setState({ marginLeft });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ likesPerDay: newValue });
  };

  handleAiResponseSliderChange = (event, newValue) => {
    this.setState({ aiResponsePercentage: newValue });
  };

  validatePhoneNumber = (phoneNumber) => {
    let cleaned = phoneNumber.replace(/^(\+?1)/, '');
    cleaned = cleaned.replace(/\D/g, '');
    const isValid = cleaned.length === 10;
    return isValid ? cleaned : '';
  };

  handleSubmit = async () => {

    console.log("I AM GOING TO CALL HANDLESUBMIT")
    const { fullName, phoneNumber, likesPerDay, aiResponsePercentage, checkboxChecked, id, isFreeTrial, deviceId, subscription, shouldKeepProfileActive, isActive } = this.state;
    
    this.setState({ loading: true });

    const validPhoneNumber = this.validatePhoneNumber(phoneNumber);
    const isValid = fullName !== '' && validPhoneNumber !== '' && checkboxChecked;
  
    if (isValid) {
      console.log("I AM GOING TO CALL HANDLESUBMIT VALID")

      try {
        // Prepare the user details to be sent to the backend
        const userDetails = {
          name: fullName,
          phoneNumber: validPhoneNumber,
          sendMaxLikes: likesPerDay,
          sendMaxResponses: aiResponsePercentage,
          isAcked: checkboxChecked,
          email: this.props.emailId,
          id: id,
          isFreeTrial: isFreeTrial,
          deviceId: deviceId,
          subscription: subscription,
          shouldPause: !shouldKeepProfileActive, // Add shouldPause value
        };
  
        // Call the updateUser service
        const updatedUser = await updateUser(id, userDetails);
  
        console.log('User updated:', updatedUser);
        
        this.props.setUserData(updatedUser.user);
        this.setState({ loading: false });

        if (!isActive) {
          this.props.navigate('/areyouready');
        }
  
        this.props.onSubmit(); 
      } catch (error) {
        console.error('Error updating user:', error);
        this.setState({ loading: false });
      }
    } else {
      this.setState({ isValid: false, isPhoneValid: validPhoneNumber !== '' });
      console.log('Please fill in all fields with valid data and accept the terms.');
    }
  };
  

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleCheckboxChange = (event) => {
    this.setState({ checkboxChecked: event.target.checked });
  };

  render() {
    const { fullName, phoneNumber, likesPerDay, aiResponsePercentage, isValid, fadeIn, isPhoneValid, marginLeft, checkboxChecked, isActive } = this.state;

    const checkboxText = aiResponsePercentage > 0
    ? `I authorize theloveguru.ai to send likes and AI-generated messages from my dating profile. I understand that theloveguru.ai is not responsible for the content or effectiveness of these messages and is not liable for any outcomes, including personal or emotional consequences from matches or communications.`
    : `I authorize theloveguru.ai to send likes from my dating profile. I understand that theloveguru.ai is not liable for any outcomes, including personal or emotional consequences from matches.`;
  
  
    const buttonText = isActive 
    ? 'Update'
    : 'I Am Ready'

    return (
        <div style={{ 
            maxWidth: '500px', // Limit the maximum width of the container to 500px
            width: '100%', // Allow the container to take up the full width until 500px
            margin: '0 auto', // Center the container horizontally
            padding: '0 10px', // Add padding for smaller screens
          }}>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <TextField
            name="fullName"
            label="Your full name"
            variant="outlined"
            size="small"
            fullWidth
            value={fullName}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            slotProps={textFieldSlotProps(isValid || fullName !== '')}
            sx={textFieldSx(isValid || fullName !== '')}
          />
        </div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <TextField
            name="phoneNumber"
            label="Your phone number associated with Hinge"
            variant="outlined"
            size="small"
            fullWidth
            value={phoneNumber}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            slotProps={textFieldSlotProps(isPhoneValid)}
            sx={textFieldSx(isPhoneValid)}
          />
        </div>
        <div style={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '30px', marginLeft, opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>Send {likesPerDay} Likes Per Day</div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Slider
            value={likesPerDay}
            onChange={this.handleSliderChange}
            aria-labelledby="continuous-slider"
            min={100}
            max={800}
            step={100}
            marks
            valueLabelDisplay="auto"
            sx={sliderSx}
          />
        </div>
        <div style={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '30px', marginLeft, opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>Send {aiResponsePercentage} AI Responses to Prompts Per Day</div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Slider
            value={aiResponsePercentage}
            onChange={this.handleAiResponseSliderChange}
            aria-labelledby="discrete-slider"
            min={0}
            max={50}
            step={10}
            marks
            valueLabelDisplay="auto"
            sx={sliderSx}
          />
        </div>
        <div style={{ color: 'white', marginBottom: '40px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in', width: '100%' }}>
        {
          isActive && (
            <div style={{ marginTop: '20px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-10px' }}> {/* Move both checkbox and label to the left */}
              <Checkbox
                  checked={this.state.shouldKeepProfileActive}
                  onChange={(event) => this.setState({ shouldKeepProfileActive: event.target.checked })}
                  color="primary"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.5rem', // Adjusted to be slightly bigger than default
                      color: 'white',
                    },
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  }}
                />
                <div style={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginLeft: '8px' }}>
                  Keep My Profile Active
                </div>
              </div>
            </div>
          )
        }
        <FormControlLabel
            control={
            <Checkbox
              checked={checkboxChecked}
              onChange={this.handleCheckboxChange}
              color="primary"
              sx={{
                alignSelf: 'flex-start',
                marginBottom: '0px',
                '& .MuiSvgIcon-root': {
                  color: 'white', // Set the border to white when unchecked
                },
                '&.Mui-checked': {
                  color: 'white', // Change checkbox color to white when checked
                },
                '&:focus-visible': {
                  outline: 'none', // Remove the blue outline when focused
                  boxShadow: 'none', // Remove any shadow that might appear
                },
              }}
            />
            }
            label={checkboxText}
            labelPlacement="end"
            sx={{
              display: 'flex',    // Flexbox to control alignment of checkbox and text
              alignItems: 'flex-start', // Align checkbox and text at the top
              marginTop: '20px', // Add some space above to move it down
              '& .MuiFormControlLabel-label': {
                fontSize: '10px', // Adjust text size as needed
                whiteSpace: 'normal', // Allow text to wrap normally
                wordWrap: 'break-word', // Break long words if necessary
                lineHeight: '1.5', // Adjust line height for better spacing between lines
                marginLeft: '8px', // Add some space between the checkbox and text
                maxWidth: 'calc(100% - 32px)', // Ensure label does not overflow, accounting for checkbox size
              },
            }}
        />

        </div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
        <Button
          variant="contained"
          sx={{
            ...buttonSx,
            color: this.state.loading ? 'white' : 'white', 
          }}
          onClick={this.handleSubmit}
          disabled={!checkboxChecked || this.state.loading}  // Disable button if loading or checkbox not checked
        >
          {this.state.loading ? (
            <>
              <span style={{ color: 'white', marginRight: '8px' }}>Updating</span>
              <CircularProgress size={24} color="inherit" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius: '80%' }} />
            </>
          ) : (
            buttonText
          )}
        </Button>
        </div>
      </div>
    );
  }
}

// Wrap ExistingUserDetails with navigate
function ExistingUserDetailsInputWithNavigate(props) {
    const navigate = useNavigate();
    return <ExistingUserDetailsInput {...props} navigate={navigate} />;
  }
  

export default ExistingUserDetailsInputWithNavigate;