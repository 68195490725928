import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { sendOtp, validateOtpAndLogin } from '../services/otpservice'; // Import the service
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Lottie from 'react-lottie';
import checkmarkAnimation from './assets/checkmark.json'; // Adjust the path as necessary

const LOCALHOST_CAPTCHA_KEY = '6Lfz8V0qAAAAANpJ4aLrz48_lEFeOzDqTJIQPvhE';
const LIVE_CAPTCHA_KEY = '6LewbGgqAAAAAPwcObc0uLarTJCPMhqfObzoWaIO';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: checkmarkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const lottieWrapperSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '70px',
  height: '20px',
  marginLeft: '8px', // Add margin to separate from text
  marginTop: '0px'
};

const textFieldSx = (isValid) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on error
    },
    '&:hover fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on focus
    },
  },
  width: '100%', // Ensure text field takes up 100% of its container

  '& input': {
    backgroundColor: 'transparent', // Transparent input field background
    color: 'white', // Ensure the text color is white
    '-webkit-text-fill-color': 'white', // Ensure text color for autofill is white
  },

  // Override autofill styles
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 1000px black inset', // Ensure input background is black
    '-webkit-text-fill-color': 'white', // Ensure text color for autofill
    caretColor: 'white', // Ensure caret (cursor) color is white
  },

  // Override selected text styles
  '& input::selection': {
    background: 'rgba(255, 255, 255, 0.3)', // Slight transparent white background for selected text
    color: 'white', // Ensure selected text stays white
  },
  '& input:-webkit-autofill::selection': {
    background: 'rgba(255, 255, 255, 0.3)', // Slight transparent white background for autofill selected text
    color: 'white', // Ensure autofill selected text stays white
  },
});


const buttonSx = {
  backgroundColor: 'black',
  color: 'white',
  width: '100%', // Make the button 100% width to match the text field
  marginTop: '10px', // Space between the button and the input
  border: '2.5px solid grey', // Add a grey border
  '&:hover': {
    backgroundColor: 'gray',
  },
};


class Email extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      code: '',
      isValid: true,
      submitted: false,
      fadeIn: false,
      resendDisabled: false, 
      resendSuccess: false, 
    };
  }

  componentDidMount() {
    this.triggerFadeIn();
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleResendCode = async () => {
    const { email } = this.state;
    const { googleReCaptchaProps } = this.props;
  
    if (googleReCaptchaProps && googleReCaptchaProps.executeRecaptcha) {
      try {
        const token = await googleReCaptchaProps.executeRecaptcha('resend');
        await sendOtp(email, token);
        this.setState({ resendSuccess: true, resendDisabled: true });
  
        setTimeout(() => {
          this.setState({ resendSuccess: false });
        }, 1000); // Show success checkmark for 1 second
  
        setTimeout(() => {
          this.setState({ resendDisabled: false });
        }, 30000); // Enable resend button after 30 seconds
  
      } catch (error) {
        console.error('Error resending OTP:', error);
      }
    }
  };
  

  handleEmailSubmit = async () => {
    const { email } = this.state;
    const { googleReCaptchaProps } = this.props; // Access googleReCaptchaProps
  
    if (this.validateEmail(email)) {
      try {
        if (googleReCaptchaProps && googleReCaptchaProps.executeRecaptcha) {
          let token = await googleReCaptchaProps.executeRecaptcha('submit'); // Trigger reCAPTCHA
          console.log('reCAPTCHA token:', token);
  
          // Send OTP request with the token
          await sendOtp(email, token);
  
          // If OTP request is successful, move to the next step
          this.setState({ submitted: true });
          this.props.onEmailSubmitted(email);
  
        } else {
          console.error('Execute recaptcha not available');
        }
      } catch (error) {
        console.error('Error:', error);
  
        // If the backend returns a 400 error (reCAPTCHA validation failure)
        if (error.message.includes('reCAPTCHA validation failed')) {
          console.log('ReCAPTCHA validation failed, retrying...');
  
          // Re-execute reCAPTCHA
          if (googleReCaptchaProps && googleReCaptchaProps.executeRecaptcha) {
            const token = await googleReCaptchaProps.executeRecaptcha('submit'); // Trigger reCAPTCHA again
            await sendOtp(email, token); // Retry sending OTP with the new token
          }
        }
      }
    } else {
      this.setState({ isValid: false });
    }
  };
  
  

  handleCodeSubmit = async () => {
    const { email, code } = this.state;
    if (code.trim()) {
      try {
        await validateOtpAndLogin(email, code);
        console.log('OTP validated, user logged in');
        // Call the parent component's onOtpVerified method
        this.props.onOtpVerified(true); 
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.log('Please enter the code.');
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.state.submitted) {
        this.handleCodeSubmit();
      } else {
        this.handleEmailSubmit();
      }
    }
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  render() {
    const { email, code, isValid, submitted, fadeIn, resendSuccess, resendDisabled } = this.state;
    
    return (
      <div style={{ width: '100%', color: 'white', padding: '0 0px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}> {/* Added padding here */}
        {!submitted ? (
          <div style={{ height: '40px', marginTop: '30px', opacity: 1, transition: 'opacity 1s ease-in' }}>
            <TextField
              name="email"
              label="Enter your email ID below"
              variant="outlined"
              size="small"
              fullWidth
              value={email}
              onChange={this.handleChange}
              onKeyUp={this.handleKeyPress}
              type="email"
              slotProps={{
                inputLabel: { style: { color: isValid ? 'white' : '#FFA07A' } },
                input: { style: { color: 'white', backgroundColor: 'transparent', caretColor: 'white' } }
              }}
              sx={textFieldSx(isValid)}
            />
          </div>
        ) : (
          <div style={{ height: '40px', marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
            <TextField
              name="code"
              label="Check your email and enter the code here"
              variant="outlined"
              size="small"
              fullWidth
              value={code}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              type="text"
              slotProps={{
                inputLabel: { style: { color: 'white' } },
                input: { style: { color: 'white', backgroundColor: 'transparent', caretColor: 'white' } }
              }}
              sx={textFieldSx(true)}
            />
          </div>

        )}
        <div style={{ height: '40px', marginTop: '30px', opacity: 1, transition: 'opacity 1s ease-in' }}>
          <Button 
            variant="contained" 
            sx={buttonSx} // Button width is now 100% to match the text field
            onClick={submitted ? this.handleCodeSubmit : this.handleEmailSubmit}
          >
            Submit
          </Button>
          {submitted && 
          // In your render code:
            <Button 
              variant="text" 
              sx={{ color: 'white', marginTop: '20px' }}
              onClick={this.handleResendCode}
              disabled={resendDisabled}
            >
            {resendSuccess ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: 'white' }}>Resend Code</span>
                <div style={lottieWrapperSx}>
                  <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
                </div>
              </div>
            ) : 'Resend Code'}

            </Button>
          }
        </div>
      </div>
    );
  }
}

// Higher-Order Component to wrap with GoogleReCaptchaProvider
const WrappedEmail = withGoogleReCaptcha(Email);

class EmailInput extends Component {
  
  getGoogleCaptchaSiteKey() {
    
    const hostname = window.location.hostname;
  
    return hostname === 'localhost' ? LOCALHOST_CAPTCHA_KEY : LIVE_CAPTCHA_KEY;
  }
  

  render() {

    const {onEmailSubmitted, onOtpVerified} = this.props;
    
    return (
      <GoogleReCaptchaProvider reCaptchaKey={this.getGoogleCaptchaSiteKey()}>
        <WrappedEmail 
          onEmailSubmitted={onEmailSubmitted}
          onOtpVerified={onOtpVerified}
        />
      </GoogleReCaptchaProvider>
    );
  }
}

export default EmailInput;
