import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createUser } from '../services/userservice'; // Import the createUser service
import { v5 as uuidv5 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const NAMESPACE = '6ba7b810-9dad-11d1-80b4-00c04fd430c8'; // Replace with a unique namespace

const textFieldSlotProps = (isValid) => ({
  inputLabel: {
    style: { color: isValid ? 'white' : '#FFA07A' }, // Light salmon for error text color
  },
  input: {
    style: {
      color: 'white',
    },
  },
});

const textFieldSx = (isValid) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on error
    },
    '&:hover fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A', // Muted orange border on focus
    },
  },
  width: '100%',
  marginLeft: '10px',
  
  '@media (max-width: 600px)': {
    width: '95%',
    marginLeft: '10px',
  },
  '@media (max-width: 400px)': {
    width: '95%',
    marginLeft: '10px',
  },

  // Ensure text and autofill behave correctly
  '& input': {
    backgroundColor: 'transparent', // Transparent input field background
    color: 'white', // Ensure the text color is white
    '-webkit-text-fill-color': 'white', // Ensure text color for autofill is white
  },

  '& input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 1000px black inset', // Black background for autofill
    '-webkit-text-fill-color': 'white', // Ensure text color for autofill
    caretColor: 'white', // Ensure caret (cursor) color is white
  },

  '& input::selection': {
    background: 'rgba(255, 255, 255, 0.3)', // Transparent background for selected text
    color: 'white', // Ensure selected text stays white
  },

  '& input:-webkit-autofill::selection': {
    background: 'rgba(255, 255, 255, 0.3)', // Transparent background for autofill selected text
    color: 'white', // Ensure autofill selected text stays white
  },
});


const sliderSx = {
  color: 'white',
  '& .MuiSlider-thumb': {
    color: 'white',
  },
  '& .MuiSlider-track': {
    color: 'white',
  },
  '& .MuiSlider-rail': {
    color: 'gray',
  },
  width: '100%',
  marginLeft: '15px',

  '@media (max-width: 600px)': {
    width: '95%',
    marginLeft: '15px',
  },
  '@media (max-width: 400px)': {
    width: '95%',
    marginLeft: '15px',
  },
};

const buttonSx = {
  backgroundColor: 'black',
  borderRadius: '10px',
  border: '2.5px solid grey',
  color: 'white',
  width: '100%',
  marginLeft: '10px',
  '&:hover': {
    backgroundColor: 'gray',
  },
  '&:active': {
    backgroundColor: 'gray', // Ensures background is gray when active
    color: 'white', // Keeps text white on active state
  },
  '&:focus': {
    backgroundColor: 'black', // Keeps the button black on focus
    color: 'white', // Keeps text white on focus
    outline: 'none', // Removes the blue outline
  },
  
  '@media (max-width: 600px)': {
    width: '95%',
    marginLeft: '10px',
  },
  '@media (max-width: 400px)': {
    width: '95%',
    marginLeft: '10px',
  },
  // Override the disabled state to make the text color visible
  '&.Mui-disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',  // Change the background color of the disabled button
    color: 'rgba(255, 255, 255, 0.5)', // Light grey color for disabled text to keep it visible
    borderColor: 'grey', // Keep the border color consistent
},
};


class NewUserDetailsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      phoneNumber: '',
      likesPerDay: 100,
      aiResponsePercentage: 0,
      isValid: true,
      fadeIn: false,
      isPhoneValid: true,
      marginLeft: '10px',
      checkboxChecked: false,
    };
  }

  async componentDidMount() {
    
    this.triggerFadeIn();
    
    window.addEventListener('resize', this.updateMarginLeft);
    
    this.updateMarginLeft();

    console.log(this.props.emailId); // Add this line before generating the UUID
  }
  

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMarginLeft);
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  updateMarginLeft = () => {
    const screenWidth = window.innerWidth;
    let marginLeft = '10px';

    if (screenWidth <= 600) {
      marginLeft = '10px';
    }
    if (screenWidth <= 400) {
      marginLeft = '10px';
    }

    this.setState({ marginLeft });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ likesPerDay: newValue });
  };

  handleAiResponseSliderChange = (event, newValue) => {
    this.setState({ aiResponsePercentage: newValue });
  };

  validatePhoneNumber = (phoneNumber) => {
    let cleaned = phoneNumber.replace(/^(\+?1)/, '');
    cleaned = cleaned.replace(/\D/g, '');
    const isValid = cleaned.length === 10;
    return isValid ? cleaned : '';
  };

  handleSubmit = async () => {
    const { fullName, phoneNumber, likesPerDay, aiResponsePercentage, checkboxChecked } = this.state;
    const validPhoneNumber = this.validatePhoneNumber(phoneNumber);
    const isValid = fullName !== '' && validPhoneNumber !== '' && checkboxChecked;

    const emailId = this.props.emailId;
    const userId = uuidv5(emailId, NAMESPACE);

    const shouldUseChatGpt = aiResponsePercentage > 0;

    // Prepare the current date and 7-day trial period
    const currentDate = new Date();
    const startDate = currentDate.toISOString().split('T')[0];  // Format as YYYY-MM-DD
    const endDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]; // 7 days from now

    if (isValid) {
        try {
            // Prepare the user details to be sent to the backend
            const userDetails = {
                id: userId,
                name: fullName,
                shouldUseChatGpt: shouldUseChatGpt,
                isFreeTrial: true, // Set free trial flag
                phoneNumber: validPhoneNumber,
                tierNumber: "tier2",
                shouldLogOut: false,
                shouldPause: false,
                sendMaxLikes: likesPerDay,
                sendMaxResponses: aiResponsePercentage,
                blockList: [],
                isActive: false,
                isAcked: checkboxChecked,
                email: emailId,
                // Add subscription information
                subscription: {
                    isFreeTrial: true,  // Free trial subscription
                    type: "free_trial",
                    startDate: startDate,  // Current date
                    endDate: endDate,      // End date (7 days from start)
                }
            };

            // Call the createUser service to create the user
            const createdUser = await createUser(userDetails);

            console.log('User created:', createdUser);

            if (createdUser) {
                // Dispatch the created user data to Redux
                this.props.setUserData(createdUser);

                localStorage.setItem('userId', userId); 

                this.props.navigate('/areyouready');
            }

            // Reset form and state after successful creation
            this.setState({
                fullName: '',
                phoneNumber: '',
                likesPerDay: 100,
                aiResponsePercentage: 0,
                isValid: true,
                isPhoneValid: true,
                checkboxChecked: false,
            }, () => {
                this.triggerFadeIn();
                this.props.onSubmit(); // Call the onSubmit prop to notify the parent component
            });
        } catch (error) {
            console.error('Error creating user:', error);
        }
    } else {
        this.setState({ isValid: false, isPhoneValid: validPhoneNumber !== '' });
        console.log('Please fill in all fields with valid data and accept the terms.');
    }
  };


  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleCheckboxChange = (event) => {
    this.setState({ checkboxChecked: event.target.checked });
  };

  render() {
    const { fullName, phoneNumber, likesPerDay, aiResponsePercentage, isValid, fadeIn, isPhoneValid, marginLeft, checkboxChecked } = this.state;

    const checkboxText = aiResponsePercentage > 0
    ? `I authorize theloveguru.ai to send likes and AI-generated messages from my dating profile. I understand that theloveguru.ai is not responsible for the content or effectiveness of these messages and is not liable for any outcomes, including personal or emotional consequences from matches or communications.`
    : `I authorize theloveguru.ai to send likes from my dating profile. I understand that theloveguru.ai is not liable for any outcomes, including personal or emotional consequences from matches.`;
  
  
    return (
        <div style={{ 
            maxWidth: '500px', // Limit the maximum width of the container to 500px
            width: '100%', // Allow the container to take up the full width until 500px
            margin: '0 auto', // Center the container horizontally
            padding: '0 10px', // Add padding for smaller screens
          }}>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <TextField
            name="fullName"
            label="Enter your full name"
            variant="outlined"
            size="small"
            fullWidth
            value={fullName}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            slotProps={textFieldSlotProps(isValid || fullName !== '')}
            sx={textFieldSx(isValid || fullName !== '')}
          />
        </div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <TextField
            name="phoneNumber"
            label="Enter your phone number associated with Hinge"
            variant="outlined"
            size="small"
            fullWidth
            value={phoneNumber}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            slotProps={textFieldSlotProps(isPhoneValid)}
            sx={textFieldSx(isPhoneValid)}
          />
        </div>
        <div style={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '30px', marginLeft, opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>Send {likesPerDay} Likes Per Day</div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Slider
            value={likesPerDay}
            onChange={this.handleSliderChange}
            aria-labelledby="continuous-slider"
            min={100}
            max={800}
            step={100}
            marks
            valueLabelDisplay="auto"
            sx={sliderSx}
          />
        </div>
        <div style={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '30px', marginLeft, opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>Send {aiResponsePercentage} AI Responses to Prompts Per Day</div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Slider
            value={aiResponsePercentage}
            onChange={this.handleAiResponseSliderChange}
            aria-labelledby="discrete-slider"
            min={0}
            max={50}
            step={10}
            marks
            valueLabelDisplay="auto"
            sx={sliderSx}
          />
        </div>
        <div style={{ color: 'white', marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in', width: '100%' }}>
        <FormControlLabel
            control={
            <Checkbox
              checked={checkboxChecked}
              onChange={this.handleCheckboxChange}
              color="primary"
              sx={{
                alignSelf: 'flex-start',
                marginTop: '0px',
                '& .MuiSvgIcon-root': {
                  color: 'white', // Set the border to white when unchecked
                },
                '&.Mui-checked': {
                  color: 'white', // Change checkbox color to white when checked
                },
                '&:focus-visible': {
                  outline: 'none', // Remove the blue outline when focused
                  boxShadow: 'none', // Remove any shadow that might appear
                },
              }}
            />

            }
            label={checkboxText}
            labelPlacement="end"
            sx={{
            display: 'flex',    // Flexbox to control alignment of checkbox and text
            alignItems: 'flex-start', // Align checkbox and text at the top
            '& .MuiFormControlLabel-label': {
                fontSize: '10px', // Adjust text size as needed
                whiteSpace: 'normal', // Allow text to wrap normally
                wordWrap: 'break-word', // Break long words if necessary
                lineHeight: '1.5', // Adjust line height for better spacing between lines
                marginLeft: '8px', // Add some space between the checkbox and text
                maxWidth: 'calc(100% - 32px)', // Ensure label does not overflow, accounting for checkbox size
            },
            }}
        />
        </div>
        <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Button 
            variant="contained" 
            sx={{
              ...buttonSx,
              backgroundColor: checkboxChecked ? 'black' : 'gray',  // Black if checked, gray if not
              '&:hover': {
              backgroundColor: checkboxChecked ? 'gray' : 'gray',  // Keep gray on hover if not checked
              },
          }}
            onClick={this.handleSubmit}
            // disabled={!checkboxChecked} // Disable button if checkbox is not checked
          >
            I Am Ready
          </Button>
        </div>
      </div>
    );
  }
}

// Wrap ExistingUserDetails with navigate
function NewUserDetailsInputWithNavigate(props) {
  const navigate = useNavigate();
  return <NewUserDetailsInput {...props} navigate={navigate} />;
}


export default NewUserDetailsInputWithNavigate;
