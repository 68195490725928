import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; // Import Provider
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import store, { persistor } from './reducer/store'; // Import your Redux store and persistor
import App from './App'; // Import your main App component

ReactDOM.render(
  <Provider store={store}> {/* Wrap your app with Provider */}
    <PersistGate loading={null} persistor={persistor}> {/* Add PersistGate for persisting the store */}
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
