const REMOTE_API_URL = process.env.REACT_APP_API_BASE_URL; // Loaded from .env or hosting environment
const LOCAL_API_URL = "http://localhost:5001";

export const getApiBaseURL = () => {
  const currentUrl = window.location.href;

  if (currentUrl.includes('localhost')) {
    if (currentUrl.includes('isRemote=true')) {
      return REMOTE_API_URL; // Remote API for localhost
    } else {
      return LOCAL_API_URL; // Local API for localhost
    }
  } else if (currentUrl.includes('theloveguru.ai')) {
    return REMOTE_API_URL; // Remote API for production
  }

  throw new Error('Unknown environment: Unable to determine API base URL');
};
