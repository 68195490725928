import React, { Component } from 'react';
import Typography from '@mui/material/Typography';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false, // State to control fade-in effect
    };
  }

  componentDidMount() {
    // Trigger fade-in effect on initial render
    this.triggerFadeIn();
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10); // Delay to trigger the transition
    });
  };

  render() {
    const { fadeIn } = this.state;
    const { text, sx } = this.props; // Receiving sx prop

    return (
      <div
        style={{
          opacity: fadeIn ? 1 : 0,
          transition: 'opacity 1s ease-in',
        }}
      >
        <Typography 
          variant="h4" // You can adjust the variant or use h4 for large titles
          sx={sx}      // Applying the sx prop for responsive styles
        >
          {text}
        </Typography>
      </div>
    );
  }
}

export default Welcome;
