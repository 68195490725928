// src/reducer/user.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserById } from '../services/userservice'; // Assume you have this service

// Async action to fetch user data by ID
export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (userId, { rejectWithValue }) => {
    try {
      const userData = await getUserById(userId);
      return userData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice for user data
const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {
      name: '',
      email: '',
    },
    isLoggedIn: false,
    status: 'idle', // Track status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,   // Store any error messages
  },
  reducers: {
    setUserData: (state, action) => {
      
      console.log("setUserData reducer called", action.payload); // Debugging log

      
      state.userData = action.payload;
      state.isLoggedIn = true; // User is logged in when data is set
    },
    logOut: (state) => {

      console.log("logOut reducer called", state); // Debugging log

      state.isLoggedIn = false;
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.isLoggedIn = true; // Assume login is true when data is fetched
        state.status = 'succeeded';
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

// Export actions
export const { setUserData, logOut } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
