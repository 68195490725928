import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',      // The key used in localStorage
  storage,          // Use localStorage to persist
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedReducer, // Apply persisted reducer
  },
});

export const persistor = persistStore(store);

export default store;
