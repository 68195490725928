import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Welcome from './welcome';

// Reuse the existing styles
const textFieldSx = {
  fontSize: '34px', // Set font size to 15px
  width: '100%', // Default width
  marginLeft: '10px', // Add left padding
  color: 'white',
  // Responsive design using media queries
  '@media (max-width: 600px)': {
    width: '95%', // Shrink the text field width on small screens
    marginLeft: '10px',
  },
  '@media (max-width: 400px)': {
    width: '95%', // Further shrink the text field width on even smaller screens
    marginLeft: '10px',
  },
};

const typographySx = {
  fontSize: '15px', // Set font size to 15px
  maxWidth: '500px', // Maximum width of 500px
  margin: '0 auto', // Center the text
  textAlign: 'left', // Left-align text
  color: 'white',
  // Responsive design using media queries
  '@media (max-width: 600px)': {
    fontSize: '15px', // Keep font size 15px on small screens
    maxWidth: '95%', // Adjust max width for small screens
  },
};

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false, // State to control fade-in effect
    };
  }

  componentDidMount() {
    // Trigger fade-in effect on initial render
    this.triggerFadeIn();
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10); // Delay to trigger the transition
    });
  };

  render() {
    const { fadeIn } = this.state;

    return (
      <>
        <div style={{ width: '100%' }}>
          <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
            <Welcome sx={textFieldSx} text="Contact Us" />
          </div>
          <div style={{ marginTop: '20px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
            <Typography variant="body1" sx={typographySx}>
              If you'd like to contact us or would like support for your account contact us at support@theloveguru.ai and we would do our best to get back to you at the soonest.
            </Typography>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUs;
