import React, { Component } from 'react';
import { createSubscription } from '../services/userservice';
import { connect } from 'react-redux';

const containerSx = {
  maxWidth: '600px',
  width: 'calc(100% - 0px)',
  margin: '0 auto',
  padding: '20px',
  textAlign: 'center',
  background: 'transparent',
  borderRadius: '15px',
  boxSizing: 'border-box',
};

const titleSx = {
  fontSize: '34px',
  color: 'white',
  marginBottom: '40px', // Increased margin for better spacing
  '@media (max-width: 600px)': {
    fontSize: '30px',
  },
};

const inputContainerSx = {
  marginBottom: '25px', // Increased bottom margin for better spacing between buttons
};

class MySubscriptionUpgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: 'AZfuCE6F3agUGWfVSbrdRQSUPqbwFZpxe8w6P_P3X_0Hfgw7jKKN_YpGq251xy1GrV03Kv3-kJKEDmP_',
      processing: false,
      fadeIn: false,
      errorMessage: ''  // To display any errors
    };
  }

  async componentDidMount() {
    this.triggerFadeIn();
    try {
      await this.loadPayPalSDK();
      this.initializePayPalButton();  // Only render PayPal button
    } catch (error) {
      this.setState({ errorMessage: 'Failed to load PayPal SDK. Please try again later.' });
    }
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  loadPayPalSDK = async () => {
    if (window.paypal) {
      console.log("PayPal SDK is already loaded.");
      return;
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?components=buttons&client-id=${this.state.clientId}&intent=subscription&vault=true`;
      script.onload = () => {
        console.log("PayPal SDK loaded successfully.");
        resolve();
      };
      script.onerror = () => reject(new Error('Failed to load PayPal SDK.'));
      document.body.appendChild(script);
    });
  };

  initializePayPalButton = async () => {
    try {
      await window.paypal.Buttons({
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: 'P-87M9388097892311KM4EYVWY', // Your subscription plan ID
          });
        },
        onApprove: async (data, actions) => {
          const subscription = await actions.subscription.get();
          this.handleSubscription(subscription);
        },
        onError: (err) => {
          this.setState({ errorMessage: `PayPal Error: ${err.message}` });
        }
      }).render('#paypal-button-container');  // Render the PayPal button here
    } catch (error) {
      this.setState({ errorMessage: 'Error rendering PayPal button.' });
    }
  };

  handleSubscription = async (subscription) => {
    const { userData } = this.props;
    const customerDetails = {
      first_name: this.getFirstName(userData.name),
      last_name: this.getLastName(userData.name),
      email: userData.email,
      user_id: userData.id
    };

    this.setState({ processing: true });

    try {
      const response = await createSubscription(subscription.id, customerDetails);
      if (response.success) {
        window.location.href = '/mysubscription'; // Redirect to success page
      } else {
        this.setState({ errorMessage: 'Subscription failed. Please try again.' });
      }
    } catch (error) {
      console.error('Subscription error:', error);
      this.setState({ errorMessage: 'There was an error processing your subscription.' });
    } finally {
      this.setState({ processing: false });
    }
  };

  getFirstName = (fullName) => {
    return fullName ? fullName.split(' ')[0] : '';
  };

  getLastName = (fullName) => {
    if (!fullName) return '';
    const parts = fullName.split(' ');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  };

  render() {
    const { fadeIn, errorMessage } = this.state;

    return (
      <div style={{ ...containerSx, opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
        <h1 style={titleSx}>Subscribe for $80 per month</h1>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} {/* Show error message */}

        {/* PayPal button container */}
        <div id="paypal-button-container" style={inputContainerSx}></div>
      </div>
    );
  }
}

// Map Redux state to props
const mapStateToProps = (state) => ({
  userData: state.user.userData,
});

export default connect(mapStateToProps)(MySubscriptionUpgrade);