import React, { Component } from 'react';

class ComingSoon extends Component {
  render() {
    return (
      <div style={{ color: 'white', textAlign: 'center', marginTop: '50px' }}>
        <h1>Coming Soon</h1>
      </div>
    );
  }
}

export default ComingSoon;