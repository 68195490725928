import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loadingAnimationData from './assets/ratrunninganimation.json'; 
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import verifiedprofile from './assets/hinge_verified.jpg';
import openpreferences from './assets/hinge_preferences_open.jpg';
import hingesubscription from './assets/hinge_upgrade_plus_and_x.jpg';
import WaitingForHingeOtp from './waitingforhingeotp'; 
import { connect } from 'react-redux'; // Import connect from redux
import { getEstimatedTimeByUserId } from '../services/userservice'; 
import { getUserById } from '../services/userservice'; // Add this
import { setUserData } from '../reducer/user'; // Add this
import Welcome from './welcome';

const sectionSx = {
  fontSize: '18px',
  color: 'white',
  maxWidth: '600px',
  fontStyle: 'normal',
  textAlign: 'center', 
  marginLeft: 'auto', 
  marginRight: 'auto', 
  '@media (max-width: 800px)': {
    fontSize: '17px',
    maxWidth: '100%',
  },
  '@media (max-width: 700px)': {
    fontSize: '16px',
    maxWidth: '100%',
  },
  '@media (max-width: 500px)': {
    fontSize: '15px',
    maxWidth: '100%',
  },
  '@media (max-width: 400px)': {
    fontSize: '14px',
    maxWidth: '100%',
  },
  '@media (max-width: 300px)': {
    fontSize: '13px',
    maxWidth: '100%',
  },
};

const headingSx = {
  fontSize: '25px',
  textAlign: 'center',
  '@media (max-width: 1024px)': {
    fontSize: '23px',
  },
  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
  '@media (max-width: 500px)': {
    fontSize: '21px',
  },
  '@media (max-width: 400px)': {
    fontSize: '20px',
  },
  '@media (max-width: 300px)': {
    fontSize: '19px',
  },
};

const gifSx = {
  display: 'block',
  marginTop: '10px',
  width: '100%',
  maxWidth: '200px', 
  borderRadius: '25px',
  aspectRatio: '9 / 19',
  opacity: 0.8,
  filter: 'brightness(0.95) saturate(0.9)',
  border: '5px solid black',
  marginLeft: 'auto',
  marginRight: 'auto',
  '@media (max-width: 600px)': {
    maxWidth: '220px',
  },
  '@media (max-width: 400px)': {
    maxWidth: '200px',
    aspectRatio: '9 / 24',
  },
};

const lottieWrapperSx = {
  width: '100%', // Ensure Lottie fills the container
  maxWidth: '600px',
  '@media (max-width: 600px)': {
    maxWidth: '300px', // Resize Lottie for smaller screens
    height: 'auto',
  },
  '@media (max-width: 400px)': {
    maxWidth: '250px',
  },
};

const contentBlockSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', 
  textAlign: 'center',
  width: '100%',
  maxWidth: '250px', 
  marginLeft: 'auto',
  marginRight: 'auto',
};

const buttonSx = {
  backgroundColor: 'black', 
  color: 'rgba(255, 255, 255, 0.8)', 
  width: '100%',
  maxWidth: '300px', 
  borderRadius: '10px', 
  border: '2.5px solid grey', 
  marginTop: '20px',
  '&:hover': {
    backgroundColor: 'gray',
  },
  '@media (max-width: 600px)': {
    marginLeft: '10px',
    marginRight: '10px',
    width: '95%',
  },
  '@media (max-width: 300px)': {
    marginLeft: '10px',
    marginRight: '10px',
    width: '95%',
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',  
    color: 'rgba(255, 255, 255, 0.5)', 
    borderColor: 'grey', 
  },
};

class AreYouReadyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: true,
      countdown: 20, 
      message: "Connecting to your Hinge Assistant...",
      phase: 0,  // Starting phase is 0 for Lottie animation
      fadeInterval: null,
      images: [
        { src: verifiedprofile, text: "Verify Your Account" },
        { src: hingesubscription, text: "Have A Hinge Subscription" },
        { src: openpreferences, text: "Keep Your Preferences Open" },
      ],
    };
  }

  async componentDidMount() {
    try {
      const {userData} = this.props;
      const userId = localStorage.getItem('userId'); 
      if (!userId) {
        throw new Error('User ID not found');
      }

      try {
        const userData = await getUserById(userId);
        this.props.setUserData(userData); // Dispatch to Redux
      } catch (error) {
        console.error('Error fetching user data on update:', error);
      }

      // Check if the user is already connected
      if (userData.isActive) {
        this.setState({
          message: 'You are already connected to your Hinge Assistant',
          countdown: -1,
        });
        return;  // Exit early if already connected
      }
      const estimatedTimeData = await getEstimatedTimeByUserId(userId);
      const estimatedWaitTimeSeconds = estimatedTimeData.estimatedWaitTimeSeconds;

      this.setState({
        countdown: estimatedWaitTimeSeconds,
        message: `Connecting to your Hinge Assistant, Estimated Wait time is ${estimatedWaitTimeSeconds} seconds...`,
      });

      this.countdownInterval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.countdown > 0) {
            return {
              countdown: prevState.countdown - 1,
              message: `Connecting to your Hinge Assistant, Estimated Wait time is ${prevState.countdown - 1} seconds...`,
            };
          } else if (prevState.countdown === 0) {
            return {
              countdown: prevState.countdown - 1,
              message: `Connecting to your Hinge Assistant, We are ready to connect you to your assistant...`,
            };
          } else {
            clearInterval(this.countdownInterval);
            return { countdown: -1 };
          }
        });
      }, 1000);
    } catch (error) {
      console.error('Error fetching estimated time:', error);
      this.setState({ message: 'Failed to fetch estimated time. Please try again.' });
    }

    this.startFadeEffect();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    clearInterval(this.countdownInterval);
    clearInterval(this.state.fadeInterval);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  startFadeEffect = () => {
    const fadeInterval = setInterval(() => {
      this.setState((prevState) => ({
        fadeIn: false
      }), () => {
        setTimeout(() => {
          this.setState((prevState) => ({
            fadeIn: true,
            phase: (prevState.phase + 1) % (this.state.images.length + 1) // Cycle through Lottie + images
          }));
        }, 500); // Time for fade out
      });
    }, 3000); // 4 seconds per fragment (including fade time)

    this.setState({ fadeInterval });
  };

  handleBeforeUnload = (event) => {
    const message = "Are you sure you want to exit while we are finding your assistant?";
    event.returnValue = message;
    return message;
  };

  handleLogin = () => {
    this.setState({ showWaitingForHingeOtp: true });
  };

  render() {
    const { fadeIn, message, countdown, phase, images } = this.state;
    const { showWaitingForHingeOtp } = this.state;
    const { userData } = this.props;

    if (showWaitingForHingeOtp) {
      return <WaitingForHingeOtp />;
    }

    const containerSx = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '600px', // Ensure consistent height for both Lottie and images
      transition: 'opacity 1s ease-in-out',
      opacity: fadeIn ? 1 : 0,
    };
    
    const outerContainerSx = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',  // Ensures the button and content take full page height
      position: 'relative',
    };

    const topMessageSx = {
      position: 'relative',  // Keep it relative for natural flow
      width: '100%',
      zIndex: 10,  
      textAlign: 'center',
      color: 'white',
      fontSize: '18px',
      marginBottom: '10px',  // Adjust this to control the gap below the title
    };

    const bottomButtonSx = {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '300px',
      position: 'absolute',  // Use absolute positioning
      bottom: '70px',  // Adjust the distance from the bottom of the screen
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loadingAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div style={outerContainerSx}>
        <div style={topMessageSx}>
            <Welcome text={message} sx={headingSx} />
        </div>

        <div style={containerSx}>
          {phase === 0 && (
            <div style={lottieWrapperSx}>
              <Lottie options={defaultOptions} height={'80%'} width={'80%'} />
            </div>
          )}

          {phase > 0 && (
            <div>
              <div style={contentBlockSx}>
                <Typography variant="body1" sx={sectionSx}>
                  {images[phase - 1].text}
                </Typography>
                <img src={images[phase - 1].src} alt={images[phase - 1].text} style={gifSx} />
              </div>
            </div>
          )}
        </div>
        <div style={bottomButtonSx}>
          <Button
            variant="contained"
            sx={buttonSx}
            disabled={userData.isActive || countdown >= 0} 
            onClick={this.handleLogin}
          >
            {userData.isActive ? "YOU ARE ALREADY CONNECTED" : "CONNECT ME"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userData,
});

const mapDispatchToProps = {
  setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreYouReadyScreen);
