import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Lottie from 'react-lottie';
import Button from '@mui/material/Button';
import swipingAnimationData from './assets/swipingforhomescreen.json'; // Adjust the path if necessary
import promptReplyAnimationData from './assets/promptreply.json'; // Adjust the path if necessary
import Welcome from './welcome';
import { getUserById } from '../services/userservice'; // Adjust the path if necessary
import { connect } from 'react-redux';
import { setUserData } from '../reducer/user'; // Adjust the path if necessary
import { Link } from 'react-router-dom'; // Import Link
import Logo from './assets/iconnew.png';

const titleSx = {
  fontSize: '34px',
  fontWeight: 'bold',
  color: 'white',
  maxWidth: '800px',
  margin: '0 auto',
  textAlign: 'center',

  // Responsive design using media queries
  '@media (max-width: 600px)': {
    fontSize: '30px',
    maxWidth: '98%',
    fontWeight: 'bold',
    marginLeft: '2px',
  },
  // Responsive design using media queries
  '@media (max-width: 500px)': {
    fontSize: '29px',
    maxWidth: '98%',
    fontWeight: 'bold',
    marginLeft: '2px',
  },
  // Responsive design using media queries
  '@media (max-width: 450px)': {
    fontSize: '24px',
    maxWidth: '98%',
    fontWeight: 'bold',
    marginLeft: '2px',
  },
  '@media (max-width: 400px)': {
    fontSize: '19.5px',
    maxWidth: '100%',
    fontWeight: 'bold',
    marginLeft: '0px',
  },
};

const typographySx = {
  fontSize: '17px',
  color: 'white',
  maxWidth: '600px',
  margin: '0 auto',
  textAlign: 'center',

  // Responsive design using media queries
  '@media (max-width: 600px)': {
    fontSize: '16px',
    maxWidth: '98%',
    marginLeft: '2px',
  },
  // Responsive design using media queries
  '@media (max-width: 500px)': {
    fontSize: '15px',
    maxWidth: '98%',
    marginLeft: '2px',
  },
  // Responsive design using media queries
  '@media (max-width: 450px)': {
    fontSize: '15px',
    maxWidth: '98%',
    marginLeft: '2px',
  },
  '@media (max-width: 400px)': {
    fontSize: '14px',
    maxWidth: '98%',
    marginLeft: '2px',
  },
};

const animationContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px', // Space between the animations

  // Responsive design using media queries
  '@media (max-width: 600px)': {
    flexDirection: 'column', // Stack vertically on small screens
    gap: '10px',
  },
};

const lottieStyle = {
  width: '100%', // Make Lottie animations responsive
  maxWidth: '300px', // Set max width for larger screens
  height: 'auto', // Maintain aspect ratio
};

const buttonSx = {
  backgroundColor: 'black',  // Set button background color to black
  color: 'white',            // Set text color to white
  width: '80%', // Adjust width to account for left and right margins, make it smaller
  marginLeft: '0px',        // Set left margin
  marginRight: '10px',       // Set right margin
  fontSize: '15px',
  marginTop: '40px',         // Add margin at the top to create space between Lottie and button
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',  // Smooth transition for hover effect
  border: '2.5px solid grey',  // Add a grey border

  '&:hover': {
    backgroundColor: 'gray', // Change background color on hover
    transform: 'scale(1.05)',  // Slight scale up effect
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',  // Deepen the shadow on hover
    borderColor: 'darkgray',  // Make the border slightly darker on hover
  },

  // Adjust margin for smaller screens, but keep the button width consistent
  '@media (max-width: 500px)': {
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '14px',
  },
  '@media (max-width: 400px)': {
    // marginRight: '10px',
    fontSize: '14px',
  },
};


class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false, // State to control fade-in effect
    };
  }

  async componentDidMount() {
    const userId = localStorage.getItem('userId');
  
    if (userId) {
      try {
        const userData = await getUserById(userId);
        console.log('User data:', userData);
        // Dispatch the fetched user data to the Redux store
        this.props.setUserData(userData);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    }
  
    // Trigger fade-in effect on initial render
    this.triggerFadeIn();
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10); // Delay to trigger the transition
    });
  };

  render() {
    const { fadeIn } = this.state;

    const lottieSwipingOptions = {
      loop: true,
      autoplay: true,
      animationData: swipingAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    const lottieReplyingOptions = {
      loop: true,
      autoplay: true,
      animationData: promptReplyAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <>
        <div style={{ width: '100%', textAlign: 'center' }}>
        <div style={{ color: 'white', marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
        <Welcome
          sx={titleSx}
          text={
            <>
              <img
                src={Logo}
                alt="Logo"
                style={{ width: '55px', height: '55px', verticalAlign: 'middle' }}
              />
              The Love Guru is your AI Dating Assistant{' '}
            </>
          }
        />
      </div>
          <div style={{ color: 'white', marginTop: '40px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
            <Typography variant="body1" sx={typographySx}>
              We swipe for you and break the ice on Hinge for busy single professionals.
            </Typography>
          </div>
          <div style={animationContainerSx}>
            <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in', ...lottieStyle }}>
              <Lottie options={lottieSwipingOptions} />
            </div>
            <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in', ...lottieStyle }}>
              <Lottie options={lottieReplyingOptions} />
            </div>
          </div>
          <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Button 
            variant="contained" 
            sx={buttonSx}
            component={Link} // Use the Link component from react-router-dom
            to="/howitworks" 
          >
            How It Works
          </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setUserData,
};

export default connect(null, mapDispatchToProps)(HomePage);

