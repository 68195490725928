import React, { Component } from 'react';
import Welcome from './welcome';
import HingeOtpInput from './hingeotpinput'; // Import the HingeOtpInput component
import { loginWithPhoneNumber } from '../services/userservice'; // Import the loginWithPhoneNumber API

class WaitingForHingeOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0, // Step to track which text to display
      fadeIn: false, // State to control fade-in effect
      loginStatus: 'pending', // Track login status
      errorMessage: null, // Track any errors
    };
  }

  componentDidMount() {
    this.startTextSequence();
    this.handleLoginWithPhoneNumber(); // Call the loginWithPhoneNumber API on mount
  }

  // Function to call loginWithPhoneNumber API
  handleLoginWithPhoneNumber = async () => {
    const userId = localStorage.getItem('userId'); 
    try {
      const response = await loginWithPhoneNumber(userId); // Call the login API
      console.log('Login successful:', response);
      this.setState({ loginStatus: 'success' });
    } catch (error) {
      console.error('Login failed:', error);
      this.setState({ loginStatus: 'failed', errorMessage: 'Failed to log in with phone number' });
    }
  };

  startTextSequence = () => {
    // Display each message with a delay of 5 seconds
    this.triggerFadeIn(); // Start the first fade-in immediately
    this.textSequence = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.currentStep < 2) {
          return { currentStep: prevState.currentStep + 1 };
        } else {
          clearInterval(this.textSequence); // Stop the sequence once all texts are shown
          return { currentStep: prevState.currentStep + 1 }; // Proceed to HingeOtpInput after the last message
        }
      });
    }, 8000); // Change text every 8 seconds
  };

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10); // Delay to trigger the transition
    });
  };

  componentDidUpdate(_, prevState) {
    if (prevState.currentStep !== this.state.currentStep && this.state.currentStep <= 2) {
      this.triggerFadeIn(); // Trigger fade-in when the step changes
    }
  }

  componentWillUnmount() {
    // Clear the interval if the component unmounts
    clearInterval(this.textSequence);
  }

  render() {
    const { currentStep, fadeIn, loginStatus, errorMessage } = this.state;

    const fadeInStyle = {
        opacity: fadeIn ? 1 : 0,
        color: 'white',  // Set text color to white
        transition: 'opacity 1s ease-in-out', // Ensure the text fades in and out smoothly
      };
      

    if (loginStatus === 'failed') {
      return (
        <>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Welcome sx={fadeInStyle} text={errorMessage}/> {/* Apply style here */}
          </div>
        </>
      );
    }

    let message;
    switch (currentStep) {
      case 0:
        message = "Connecting to your Hinge Assistant...";
        break;
      case 1:
        message = "Hinge Assistant is available and ready...";
        break;
      case 2:
        message = "Connecting to your Hinge Account...";
        break;
      default:
        if (loginStatus === 'success') {
          return <HingeOtpInput />; // Once login is successful and the sequence is done, render the HingeOtpInput component
        } else {
          return null; // Waiting for login to complete
        }
    }

    return (
      <>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div style={{ marginTop: '30px', ...fadeInStyle }}>
          <Welcome text={message} sx={fadeInStyle} /> {/* Apply style here */}
          </div>
        </div>
      </>
    );
  }
}

export default WaitingForHingeOtp;
