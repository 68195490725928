import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import gifPath from './assets/allmatches.gif';
import verifiedprofile from './assets/hinge_verified.jpg';
import openpreferences from './assets/hinge_preferences_open.jpg';
import hingesubscription from './assets/hinge_upgrade_plus_and_x.jpg';
import promptResponseImage from './assets/promptresponses.jpg';
import focusOnDatingImage from './assets/whileyoufocusondating.jpg';
import Welcome from './welcome';

const sectionSx = {
  fontSize: '23px',
  fontStyle: 'normal',
  // fontWeight: 'bold',
  textAlign: 'center',
  padding: '10px',
  margin: '10px 0',
  width: '100%',
  wordWrap: 'break-word',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3, // Limit text to 3 lines maximum
  overflow: 'hidden', // Hide overflow when text exceeds 3 lines
  textOverflow: 'ellipsis', // Add ellipsis for overflow text
  '@media (max-width: 738px)': {
    fontSize: '22.5px',
  },
  '@media (max-width: 630px)': {
    fontSize: '21.5px',
  },
  '@media (max-width: 580px)': {
    fontSize: '21px',
  },
  '@media (max-width: 520px)': {
    fontSize: '20px',
  },
  '@media (max-width: 450px)': {
    fontSize: '19.5px',
  },
  '@media (max-width: 420px)': {
    fontSize: '19px',
  },
  '@media (max-width: 390px)': {
    fontSize: '18px',
  },
  '@media (max-width: 370px)': {
    fontSize: '17px',
  },
  '@media (max-width: 350px)': {
    fontSize: '16px',
  },
};

const headingSx = {
  fontSize: '34px',
  textAlign: 'center',
  '@media (max-width: 1024px)': {
    fontSize: '32px',
  },
  '@media (max-width: 768px)': {
    fontSize: '30px',
  },
  '@media (max-width: 500px)': {
    fontSize: '28px',
  },
};

const textAlignLeftSx = {
  textAlign: 'center',
  marginLeft: '0px',
  marginTop: '80px',
  marginBottom: '10px',
  fontSize: '34px',
  '@media (max-width: 1024px)': {
    fontSize: '32px',
    marginTop: '70px',
  },
  '@media (max-width: 768px)': {
    fontSize: '30px',
    marginTop: '60px',
  },
  '@media (max-width: 500px)': {
    fontSize: '28px',
    marginTop: '90px',
  },
};

const imageSx = {
  width: '100%', // Ensure images take up the full width of their container
  aspectRatio: '9 / 19', // Maintain aspect ratio
  opacity: 0.90,
  // filter: 'brightness(0.75) saturate(0.8)',
  border: '9px solid black',
  borderRadius: '29px',
  '@media (max-width: 375px)': { // For iPhone SE/5 and similar small screen sizes
    width: '100%',
    maxWidth: '250px',
    aspectRatio: '9 / 18', // Further reduce aspect ratio for taller appearance
  },
};


const contentGridSx = {
};

const contentBlockSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // Ensure text stays close to the image
  alignItems: 'center',
  textAlign: 'center',
  width: '100%', // Full width of the grid item
  maxWidth: '400px',
  marginTop: '70px', // Default value for small screens
  
  // Apply media query rules in descending order of screen size
  '@media (min-width: 1025px)': { // Large screens
    maxWidth: '350px',
    marginTop: '70px',
  },
  '@media (max-width: 1024px)': { // Medium screens like tablets
    maxWidth: '280px',
    marginTop: '50px',
  },
  '@media (max-width: 768px)': { // Smaller tablets or landscape phones
    maxWidth: '250px',
    marginTop: '40px',
  },
  '@media (max-width: 500px)': { // Small devices like iPhones
    maxWidth: '230px',
    marginTop: '10px !important', // Force it to 20px for smaller devices
  },
};


class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
    };
  }

  componentDidMount() {
    this.triggerFadeIn();
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  render() {
    const { fadeIn } = this.state;

    const containerSx = {
      color: 'white',
      width: '100%',
      padding: '10px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: '120px',
      paddingBottom: '100px',
    };

    const fadeInStyle = {
      opacity: fadeIn ? 1 : 0,
      transition: 'opacity 1s ease-in',
    };

    return (
      <>
        <div style={containerSx}>
          <div>
            <Welcome text="Get Started" sx={headingSx} />
          </div>

          <div style={contentGridSx}>
            <div style={{ ...contentBlockSx, ...fadeInStyle }}>
              <Typography variant="body1" sx={sectionSx}>
                1. Verify Your Hinge Profile
              </Typography>
              <img src={verifiedprofile} alt="Verify profile" style={imageSx} />
            </div>

            <div style={{ ...contentBlockSx, ...fadeInStyle }}>
              <Typography variant="body1" sx={sectionSx}>
                2. Have A Hinge Subscription
              </Typography>
              <img src={hingesubscription} alt="Have a Hinge Subscription" style={imageSx} />
            </div>

            <div style={{ ...contentBlockSx, ...fadeInStyle }}>
              <Typography variant="body1" sx={sectionSx}>
                3. Have Open Dating Preferences
              </Typography>
              <img src={openpreferences} alt="Keep Preferences Open" style={imageSx} />
            </div>
          </div>

          <div>
            <Welcome text="What We Do" sx={textAlignLeftSx} />
          </div>
          <div style={contentGridSx}>
            <div style={{ ...contentBlockSx, ...fadeInStyle }}>
              <Typography variant="body1" sx={sectionSx}>
                1. We Get You Matches
              </Typography>
              <img src={gifPath} alt="All Matches" style={imageSx} />
            </div>

            <div style={{ ...contentBlockSx, ...fadeInStyle }}>
              <Typography variant="body1" sx={sectionSx}>
                2. We Reply To Prompts
              </Typography>
              <img src={promptResponseImage} alt="Prompt Responses" style={imageSx} />
            </div>

            <div style={{ ...contentBlockSx, ...fadeInStyle }}>
              <Typography variant="body1" sx={sectionSx}>
                3. While You Focus On Dating
              </Typography>
              <img src={focusOnDatingImage} alt="Focus on Dating" style={imageSx} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HowItWorks;
