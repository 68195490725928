import {getApiBaseURL} from './util'

var apiBaseURL = "http://34.132.57.0:5001"; // Your backend API base URL

// Function to fetch user details by user ID
export const getUserById = async (userId) => {
  try {
    // Retrieve the JWT token from localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error('No authentication token found. Please log in.');
    }

    if (localStorage.getItem('isProd') !== 'true') {
      localStorage.setItem('isProd', 'false');
    }

    apiBaseURL = getApiBaseURL()

    const response = await fetch(`${apiBaseURL}/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Pass the token in the Authorization header
      },
    });

    if (userId) {
        localStorage.setItem('userId', userId); 
    }

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to fetch user');
    }
    console.log("blah blahhhhhh")
    return data; // Return the user data
  } catch (error) {
    console.error('Error fetching user:', error);
    return {
        name: '',
        email: '',
    }
  }
};

export const createUser = async (userDetails) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found. Please log in.');
      }
      
      apiBaseURL = getApiBaseURL()
  
      const response = await fetch(`${apiBaseURL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(userDetails),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to create user');
      }
  
      if (data.userId) {
        localStorage.setItem('userId', data.userId);
      }
  
      return data; 
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
};
  
export const updateUser = async (userId, updatedUserDetails) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found. Please log in.');
      }

      apiBaseURL = getApiBaseURL()
  
      const response = await fetch(`${apiBaseURL}/users/${userId}`, {
        method: 'PUT', // Change to PUT
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedUserDetails),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to update user');
      }
  
      return data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };
  
  export const inputOTPToPhoneNumber = async (userId, otp, otpType) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('No authentication token found. Please log in.');
  
      apiBaseURL = getApiBaseURL()

      const response = await fetch(`${apiBaseURL}/inputOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ userId, otp, otpType }), // Include otpType here
      });
  
      const data = await response.json();
  
      if (response.ok) {
        return data; // Return the data directly if response is ok
      } else {
        throw new Error(data.status || 'Failed to input OTP');
      }
    } catch (error) {
      console.error('Error inputting OTP:', error);
      throw error;
    }
  };
  
  
  

  export const loginWithPhoneNumber = async (userId) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found. Please log in.');
      }
  
      apiBaseURL = getApiBaseURL()

      const response = await fetch(`${apiBaseURL}/loginWithPhoneNumber/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to log in with phone number');
      }
  
      return data;
    } catch (error) {
      console.error('Error logging in with phone number:', error);
      throw error;
    }
  };
  
// Function to fetch estimated time by user ID
export const getEstimatedTimeByUserId = async (userId) => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error('No authentication token found. Please log in.');
    }

    apiBaseURL = getApiBaseURL()

    const response = await fetch(`${apiBaseURL}/getEstimatedTime/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to fetch estimated time');
    }

    return data; // Return the estimated wait time data
  } catch (error) {
    console.error('Error fetching estimated time:', error);
    throw error;
  }
};

// Braintree-specific functions

// Function to fetch Braintree client token
export const getClientToken = async () => {
  try {  
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error('No authentication token found. Please log in.');
    }

    apiBaseURL = getApiBaseURL()

    const response = await fetch(`${apiBaseURL}/getClientToken`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to get client token');
    }

    return data.clientToken;
  } catch (error) {
    console.error('Error fetching Braintree client token:', error);
    throw error;
  }
};

// Function to create a subscription with PayPal
export const createSubscription = async (paypalSubscriptionId, customerDetails) => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error('No authentication token found. Please log in.');
    }

    apiBaseURL = getApiBaseURL()

    const response = await fetch(`${apiBaseURL}/createSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        paypal_subscription_id: paypalSubscriptionId, // Pass the PayPal Subscription ID
        first_name: customerDetails.first_name,  // Pass first name
        last_name: customerDetails.last_name,    // Pass last name
        user_id: customerDetails.user_id,        // Pass user ID
        email: customerDetails.email             // Pass email        
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Subscription creation failed');
    }

    return data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

// Function to cancel a subscription
export const cancelSubscription = async (userId, subscriptionId) => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error('No authentication token found. Please log in.');
    }

    apiBaseURL = getApiBaseURL()

    const response = await fetch(`${apiBaseURL}/cancelSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        subscription_id: subscriptionId,  // Pass the subscription ID to cancel
        user_id: userId
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Subscription cancellation failed');
    }

    return data;
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
};
