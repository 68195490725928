import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Welcome from './welcome';
import Lottie from 'react-lottie';
import celebrationAnimation from './assets/celebrationsubscription.json'; // Import your Lottie file
import freetrialAnimation from './assets/freetrialanimation.json'; // Import your Lottie file
import { Link } from 'react-router-dom';
import { cancelSubscription } from '../services/userservice'; // Import the cancel function
import { setUserData } from '../reducer/user'; // Redux action
import { getUserById } from '../services/userservice'; // Service to get user by ID

const containerSx = {
  maxWidth: '500px',
  width: '100%',
  margin: '0 auto',
  padding: '0 10px',
  textAlign: 'left', // Align the text to the left

  // Responsive design for smaller screens
  '@media (max-width: 600px)': {
    maxWidth: '95%',
    padding: '0 8px',
  },
};

const titleSx = {
  fontSize: '34px',
//   fontWeight: 'bold',
  color: 'white',
  marginBottom: '10px', // Add gap below the title
  textAlign: 'center', // Center align the title

  // Responsive title for smaller screens
  '@media (max-width: 600px)': {
    fontSize: '30px',
    marginBottom: '10px',
  },
  '@media (max-width: 400px)': {
    fontSize: '25px',
    marginBottom: '5px',
  },
};

const typographyLabelSx = {
  fontSize: '16px',
//   fontWeight: 'bold',
  color: 'white',
  marginBottom: '10px',
  textAlign: 'left', // Left align the labels

  // Responsive labels for smaller screens
  '@media (max-width: 600px)': {
    fontSize: '14px',
    marginBottom: '8px',
  },
  '@media (max-width: 400px)': {
    fontSize: '12px',
    marginBottom: '6px',
  },
};

const typographyValueSx = {
  fontSize: '18px',
  color: 'rgba(255, 255, 255, 0.8)', // Make the value more prominent
  textTransform: 'uppercase', // Ensure "Free Trial" is in all caps
  marginBottom: '25px', // Add larger gap between sections
  textAlign: 'left', // Left align the value

  // Responsive value for smaller screens
  '@media (max-width: 600px)': {
    fontSize: '16px',
    marginBottom: '20px',
  },
  '@media (max-width: 400px)': {
    fontSize: '14px',
    marginBottom: '15px',
  },
};

const buttonSx = {
    backgroundColor: 'black',
    borderRadius: '10px',
    border: '2.5px solid grey',
    color: 'white',
    width: 'calc(100% - 40px)',  // Ensure there is space for margin
    marginTop: '20px',
    marginLeft: '20px',          // Add left margin
    marginRight: '20px',         // Add right margin
    '&:hover': {
      backgroundColor: 'gray',
    },
  
    // Responsive buttons for smaller screens
    '@media (max-width: 600px)': {
      fontSize: '14px',
      padding: '12px 20px',
      marginLeft: '15px',        // Adjust left margin for smaller screens
      marginRight: '15px',       // Adjust right margin for smaller screens
    },
    '@media (max-width: 400px)': {
      fontSize: '12px',
      padding: '10px 18px',
      marginLeft: '10px',        // Adjust left margin for smaller screens
      marginRight: '10px',       // Adjust right margin for smaller screens
    },
  };
  
  const disabledButtonSx = {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',  // Slightly visible background for disabled
    borderRadius: '10px',
    color: 'rgba(255, 255, 255, 0.7)', // Lighter grey for disabled text
    border: '2.5px solid rgba(255, 255, 255, 0.5)', // Visible grey border
    cursor: 'not-allowed',    // Disabled cursor style
    width: 'calc(100% - 40px)',  // Ensure there is space for margin
    marginTop: '20px',
    marginLeft: '20px',       // Add left margin
    marginRight: '20px',      // Add right margin
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',  // Keep hover effect same for disabled
    },
  
    // Responsive disabled buttons for smaller screens
    '@media (max-width: 600px)': {
      fontSize: '14px',
      padding: '12px 20px',
      marginLeft: '15px',        // Adjust left margin for smaller screens
      marginRight: '15px',       // Adjust right margin for smaller screens
    },
    '@media (max-width: 400px)': {
      fontSize: '12px',
      padding: '10px 18px',
      marginLeft: '10px',        // Adjust left margin for smaller screens
      marginRight: '10px',       // Adjust right margin for smaller screens
    },
  };
  
  

const lottieStyle = {
    width: '100%', // Set the width to 100% of the container
    maxWidth: '400px', // Set a maximum width for larger screens
    height: 'auto', // Ensure the aspect ratio is maintained
    margin: '0 auto', // Center the animation horizontally
    '@media (max-width: 600px)': {
      maxWidth: '300px', // Decrease the max width on smaller screens
    },
    '@media (max-width: 400px)': {
      maxWidth: '250px', // Further decrease for very small screens
    },
  };
  

class MySubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      isCanceling: false, // Add a state to handle cancel process
    };
  }

  componentDidMount() {
    this.triggerFadeIn();
    this.fetchUserDetails(); // Fetch user details when component mounts
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  fetchUserDetails = async () => {
    const userId = localStorage.getItem('userId');
  
    if (userId.length > 0) {
      try {
        const userData = await getUserById(userId); // Fetch user details by ID
        console.log("Fetched user data:", userData);
  
        if (userData) {
          this.props.setUserData(userData); // Dispatch the fetched data to Redux
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    } else {
      console.error('Email ID is missing');
    }
  };

  handleCancelSubscription = async () => {
    const { subscription } = this.props.userData || {}; // Get subscription details
    
    const userId = localStorage.getItem('userId');

    if (!subscription || !subscription.paypalSubscriptionID) {
      this.setState({ errorMessage: 'No active PayPal subscription found to cancel.', isSuccess: false });
      return;
    }

    this.setState({ isCanceling: true, errorMessage: '', isSuccess: false });

    try {
      const response = await cancelSubscription(userId, subscription.paypalSubscriptionID);

      if (response.success) {
        this.setState({ errorMessage: 'Subscription canceled successfully.', isSuccess: true });
        await this.fetchUserDetails();
        // Optionally, trigger a state update or redirect the user
      } else {
        this.setState({ errorMessage: 'Failed to cancel subscription. Please try again.', isSuccess: false });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Error occurred while canceling the subscription.', isSuccess: false });
    } finally {
      this.setState({ isCanceling: false });
    }
  };

  mapSubscriptionType = (type) => {
    const subscriptionMapping = {
      free_trial: 'Free trial',
      full_version: 'Full version'
      // Add other subscription types if needed
    };
    return subscriptionMapping[type] || 'Unknown Subscription';
  };

  render() {
    const { subscription } = this.props.userData || {}; // Get subscription from userData
    const { fadeIn, isCanceling, errorMessage, isSuccess } = this.state;

    const messageSx = {
      color: isSuccess ? 'green' : 'red',  // Green for success, red for error
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '20px',
      fontWeight: 'bold',
    };

    const celebrationDefaultOptions = {
      loop: true,
      autoplay: true,
      animationData: celebrationAnimation, // Use the imported Lottie file
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    const freeTrialDefaultOptions = {
      loop: true,
      autoplay: true,
      animationData: freetrialAnimation, // Use the imported Lottie file
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    if (!subscription) {
      return (
        <div style={containerSx}>
          <Typography sx={titleSx}>No Subscription Found</Typography>
        </div>
      );
    }

    const subscriptionType = this.mapSubscriptionType(subscription.type).toUpperCase(); // Convert to all caps
    const subscriptionEndDate = new Date(subscription.endDate).toLocaleDateString();
    const isFreeTrial = subscription.type === 'free_trial'; // Check if subscription type is free_trial

    return (
      <div style={containerSx}>
        <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
          <Welcome text="Your Subscription" sx={titleSx} /> {/* Use Welcome component */}
          
          {subscription.type === 'full_version' && (
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <Lottie options={celebrationDefaultOptions} style={lottieStyle} height={420} width={500} />
            </div>
          )}
          {subscription.type === 'free_trial' && (
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <Lottie options={freeTrialDefaultOptions} style={lottieStyle} height={350} width={400} />
            </div>
          )}
          <Typography sx={typographyLabelSx}>Subscription Type:</Typography>
          <Typography sx={typographyValueSx}>{subscriptionType}</Typography>
          <Typography sx={typographyLabelSx}>
              {subscription.type === 'full_version' && subscription.isActive ? 'Subscription Renewal Date:' : 'Subscription End Date:'}
          </Typography>
          <Typography sx={typographyValueSx}>{subscriptionEndDate}</Typography>
          {/* Display errorMessage if it exists */}
          {errorMessage && (
            <Typography sx={messageSx}>
              {errorMessage}
            </Typography>
          )}
          <Button
            variant="contained"
            sx={buttonSx}
            component={Link} to="/mysubscription/upgrade"
          >
            Upgrade Subscription
          </Button>
          <Button
            variant="contained"
            sx={isFreeTrial || !subscription.isActive ? disabledButtonSx : buttonSx}
            onClick={this.handleCancelSubscription}
            disabled={isCanceling}
          >
            {isCanceling ? 'Canceling...' : 'Cancel Subscription'}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userData, // Access userData including subscription info from Redux state
});

const mapDispatchToProps = {
  setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MySubscription);
