import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData, fetchUserData, logOut } from './reducer/user';
import LoginPage from './components/login';
import About from './components/about';
import MySubscription from './components/mysubscription';
import MySubscriptionUpgrade from './components/mysubscriptionupgrade'
import ContactUs from './components/contact';
import HomePage from './components/home';
import HowItWorks from './components/howitworks.js';
import Header from './components/header.js';
import AreYouReadyScreen from './components/areyouready.js';
import WebsiteContainer from './components/websitecontainer';  
import ComingSoon from './components/comingsoon.js';
import { persistor } from './reducer/store'; // Import your Redux store and persistor

class App extends Component {

  handleTokenExpiration() {
    const token = localStorage.getItem('authToken');
    const expirationTime = localStorage.getItem('authTokenExpiration');
  
    if (token && expirationTime) {
      if (Date.now() > Number(expirationTime)) {
        // Token has expired, log the user out
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('authTokenExpiration');
        logOut(); 
        persistor.purge();
      } else {
        // Token is valid
        console.log('Token is valid');
      }
    } else {
      logOut();
      persistor.purge();
      // No token found, user needs to log in
      console.log('No token found. Please log in.');
    }
  };
  
  componentDidMount() {
    const { isLoggedIn, userData, fetchUserData } = this.props;

    const userId = localStorage.getItem('userId');

    console.log("isloggedinnnnn", isLoggedIn)
    console.log("userData", userData)
    console.log("userId", userId)

    this.handleTokenExpiration()
    if (isLoggedIn && userData && userData.email === '' && userId) {
      fetchUserData(userId);
    }
  }

  isFeatureFlagEnabled = () => {
    return localStorage.getItem('isProd') === 'true';
  };

  render() {
    const { setUserData } = this.props;

    return (
      <Router>
        {this.isFeatureFlagEnabled() ? <Header /> : <></>}
        <WebsiteContainer>
        <Routes>
        {this.isFeatureFlagEnabled() ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage setUserData={setUserData} />} />
            <Route path="/about" element={<About />} />
            <Route path="/mysubscription" element={<MySubscription setUserData={setUserData} />} />
            <Route path="/mysubscription/upgrade" element={<MySubscriptionUpgrade />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/usersettings" element={<LoginPage setUserData={setUserData} />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/areyouready" element={<AreYouReadyScreen />} />
          </>
        ) : (
          <Route path="*" element={<ComingSoon />} />
        )}
      </Routes>
        </WebsiteContainer>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  userData: state.user.userData,
});

const mapDispatchToProps = {
  setUserData,
  fetchUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
