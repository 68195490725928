import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Welcome from './welcome';

const textFieldSx = {
  fontSize: '34px', // Set font size to 15px
  width: '100%', // Default width
  marginLeft: '10px', // Add left padding
  color: 'white',
  // Responsive design using media queries
  '@media (max-width: 600px)': {
    width: '95%', // Shrink the text field width on small screens
    marginLeft: '10px',
  },
  '@media (max-width: 400px)': {
    width: '95%', // Further shrink the text field width on even smaller screens
    marginLeft: '10px',
  },
};

const typographySx = {
  fontSize: '15px', // Set font size to 15px
  maxWidth: '500px', // Maximum width of 500px
  margin: '0 auto', // Center the text
  textAlign: 'left', // Left-align text
  color: 'white',
  // Responsive design using media queries
  '@media (max-width: 600px)': {
    fontSize: '15px', // Keep font size 15px on small screens
    maxWidth: '95%', // Adjust max width for small screens
  },
};

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false, // State to control fade-in effect
    };
  }

  componentDidMount() {
    this.triggerFadeIn();
  }

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10); // Delay to trigger the transition
    });
  };

  render() {
    const { fadeIn } = this.state;

    return (
        <div style={{ width: '100%' }}>
          <div style={{ marginTop: '30px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
            <Welcome sx={textFieldSx} text="Who we are" />
          </div>
          <div style={{ marginTop: '20px', opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in' }}>
            <Typography variant="body1" sx={typographySx}>
              Our company offers a cutting-edge solution that automates the swiping and prompt-replying process on Hinge, making online dating more efficient and effective. By leveraging advanced algorithms, we help users streamline their interactions, saving time while increasing the chances of finding meaningful connections. Whether you're too busy to swipe or looking to enhance your dating experience, our service ensures you're always engaging with potential matches in the most optimal way.
            </Typography>
          </div>
        </div>
    );
  }
}

export default About;
