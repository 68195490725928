import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Welcome from './welcome';
import { Block } from 'baseui/block';
import { inputOTPToPhoneNumber } from '../services/userservice'; // Import the inputOTPToPhoneNumber function
import { connect } from 'react-redux'; // Import connect from redux
import { getUserById } from '../services/userservice'; // Adjust the path if necessary
import { setUserData } from '../reducer/user'; // Adjust the path if necessary
import CircularProgress from '@mui/material/CircularProgress';

const textFieldSlotProps = (isValid) => ({
  inputLabel: {
    style: { color: isValid ? 'white' : '#FFA07A' }, // Light salmon for error text color
  },
  input: {
    style: {
      color: 'white',
    },
  },
});

const textFieldSx = (isValid) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A',
    },
    '&:hover fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A',
    },
    '&.Mui-focused fieldset': {
      borderColor: isValid ? 'white' : '#FFA07A',
    },
  },
  width: '100%', // Make the input field full width
});

const buttonSx = {
  backgroundColor: 'black',
  color: 'white',
  width: '100%',
  marginTop: '10px',
  border: '2.5px solid grey',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', // Ensure vertical centering for spinner
  '&:hover': {
    backgroundColor: 'black',
  },
};

class HingeOtpInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      fadeOut: false,
      phoneNumberOtp: '',
      errorMessage: '',
      isValid: true, 
      loading: false, 
      phoneNumberOtpSubmitted: false,
      countdown: 50, // Initialize countdown to 50 seconds
      userFetched: false, // To track if we've already fetched user data
      isLinked: false,
      emailOtp: '',
      emailOtpSubmitted: false,
      isEditable: true,
    };
  }

  async componentDidMount(prevProps) {
    this.triggerFadeIn();
    this.startCountdown();
    await this.fetchUserData();
  }

  componentDidUpdate(prevProps, prevState) {
    // Only call fetchUserData when phoneNumberOtpSubmitted changes from false to true
    if (this.state.isLinked && !prevState.phoneNumberOtpSubmitted && !this.state.userFetched) {
      this.fetchUserData();
    }
  }

  fetchUserData = async () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      try {
        const userData = await getUserById(userId);
        this.props.setUserData(userData);
        this.setState({ userFetched: true }); // Marks as fetched to prevent reruns
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };
  

  triggerFadeIn = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => this.setState({ fadeIn: true }), 10);
    });
  };

  inputOTPToPhoneNumber = async (otp, otpType) => {
    const userId = localStorage.getItem('userId');
  
    if (userId) {
      this.setState({ loading: true });
      this.setState({ errorMessage: '' });
      try {
        const response = await inputOTPToPhoneNumber(userId, otp, otpType); 

        console.log("response.status ", response.status)
  
        if (response.status && response.status.toLowerCase().includes('success')) {
          console.log('Phone Number OTP successfully got it for:', userId);
        
          // Trigger fade-out and then set phoneNumberOtpSubmitted after fade-out is done
          this.setState({ fadeOut: true, isLinked: true, isEditable: false });
          setTimeout(() => {
            this.setState({ phoneNumberOtpSubmitted: true, emailOtpSubmitted: true, fadeOut: false, fadeIn: true, loading: false });
          }, 1000); // Delay for fade-out to finish
        
        } else if (response.status && response.status.toLowerCase().includes('profile not found')) {
          console.error('Profile not found for:', userId);
        
          // Trigger fade-out and set the error message after fade-out
          this.setState({ fadeOut: true, isEditable: false });
          setTimeout(() => {
            this.setState({ phoneNumberOtpSubmitted: false, fadeOut: false, fadeIn: true, errorMessage: "Profile not found, Use a valid phone number connected to your Hinge account", loading: false });
          }, 1000); // Delay for fade-out to finish
        
        } else if (response.status && response.status.toLowerCase().includes('invalid phone number otp')) {
          console.error('Invalid Phone Number OTP Entered:', userId);
        
          // Trigger fade-out and set the error message after fade-out
          this.setState({ fadeOut: true, isEditable: true });
          setTimeout(() => {
            this.setState({ phoneNumberOtpSubmitted: false, fadeOut: false, fadeIn: true, errorMessage: "Invalid Phone Number OTP Entered, Try Again!", loading: false });
          }, 1000); // Delay for fade-out to finish
        
        } else if (response.status && response.status.toLowerCase().includes('too many phone number')) {
          console.error('Too Many Phone Number OTPs Entered:', userId);
        
          // Trigger fade-out and set the error message after fade-out
          this.setState({ fadeOut: true, isEditable: false });
          setTimeout(() => {
            this.setState({ phoneNumberOtpSubmitted: false, fadeOut: false, fadeIn: true, errorMessage: "Too Many Phone Number OTPs Entered, Try Again Later!", loading: false });
          }, 1000); // Delay for fade-out to finish
        
        } if (response.status && response.status.toLowerCase().includes('sent to')) {
          this.setState({ fadeOut: true, isEditable: true });
          setTimeout(() => {
            this.setState({ 
              phoneNumberOtpSubmitted: true, 
              emailOtpSubmitted: false, 
              fadeOut: false, 
              fadeIn: true, 
              loading: false 
            });
          }, 1000);
        } else if (response.status && response.status.toLowerCase().includes('invalidemailotp')) {
          console.error('Invalid Email OTP Entered:', userId);
          this.setState({ fadeOut: true, isEditable: true });
          setTimeout(() => {
            this.setState({ 
              emailOtpSubmitted: false, 
              fadeOut: false, 
              fadeIn: true, 
              errorMessage: "Invalid Email OTP Entered, Try Again!", 
              loading: false 
            });
          }, 1000);
        } else {
          console.error('Failed to send Phone Number OTP:', response.status);
          this.setState({ loading: false }); // Add this line here for the error case
        }
      } catch (error) {
        console.error('Failed to send Phone Number OTP:', error);
        this.setState({ loading: false }); // Ensure loading stops on error
      }
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      const otpType = this.state.phoneNumberOtpSubmitted ? 'emailOtp' : 'phoneNumberOtp';
      this.setState({ [otpType]: value, isValid: true });
    }
  };
  
  startCountdown = () => {
    this.timer = setInterval(() => {
      this.setState(prevState => {
        if (prevState.countdown > 0) {
          return { countdown: prevState.countdown - 1 };
        } else {
          clearInterval(this.timer); // Clear timer when countdown reaches 0
          return {};
        }
      });
    }, 1000);
  };

  handleOtpSubmit = () => {
    const { phoneNumberOtp, emailOtp, phoneNumberOtpSubmitted } = this.state;
    const otpType = phoneNumberOtpSubmitted ? 'email' : 'phoneNumber';
  
    const otp = phoneNumberOtpSubmitted ? emailOtp : phoneNumberOtp;
  
    if (otp.length !== 6) {
      this.setState({ isValid: false });
    } else {
      this.setState({ isEditable: false });
      this.inputOTPToPhoneNumber(otp, otpType); // Pass otpType here
      this.startCountdown();
    }
  };

  render() {
    const { phoneNumberOtp, fadeIn, fadeOut, isValid, loading, phoneNumberOtpSubmitted, errorMessage, emailOtp } = this.state;
    const { subscription } = this.props;
  
    const blockStyle = {
      width: '100%',
      paddingLeft: '10px',
      opacity: fadeOut ? 0 : (fadeIn ? 1 : 0), // Adjust opacity for fade-in and fade-out
      transition: 'opacity 1s ease-in-out',
      color: 'white',
    };
  
    const fadeInStyle = {
      marginTop: '30px',
      opacity: fadeOut ? 0 : (fadeIn ? 1 : 0), // Adjust opacity for fade-in and fade-out
      transition: 'opacity 1s ease-in-out',
    };
  
    console.log("phoneNumberOtpSubmitted", phoneNumberOtpSubmitted);

    console.log("errorMessage", errorMessage);
  
    // Only show success messages if fadeOut has finished
    if (phoneNumberOtpSubmitted && !fadeOut) {

      this.fetchUserData();
      
      if (subscription && subscription.isFreeTrial) {
        return (
          <div style={{ width: '100%', color: 'white', padding: '0 10px' }}>
            <Block marginBottom="20px" style={blockStyle}>
              <Welcome text={`You've successfully linked your Hinge Account active until ${subscription.endDate}`} />
            </Block>
          </div>
        );
      }

      if (errorMessage.length > 0) {
        return (
          <div style={{ width: '100%', color: 'white', padding: '0 10px' }}>
            <Block marginBottom="20px" style={blockStyle}>
              <Welcome text={`Your Hinge profile is not found!`} />
            </Block>
          </div>
        );
      }
  
      return (
        <div style={{ width: '100%', color: 'white', padding: '0 10px' }}>
          <Block marginBottom="20px" style={blockStyle}>
            <Welcome text={`You've successfully linked your Hinge Account...`} />
          </Block>
        </div>
      );
    }
  
    // Initial render before OTP submission
    return (
      <div style={{ width: '100%', color: 'white', padding: '0 10px' }}>
        <Block marginBottom="20px" style={blockStyle}>
          <Welcome text={phoneNumberOtpSubmitted ? "Enter your Email OTP..." : "Enter your Hinge OTP..."} />
        </Block>
        <div style={fadeInStyle}>
        <TextField
          name={phoneNumberOtpSubmitted ? "emailOtp" : "phoneNumberOtp"}
          label={phoneNumberOtpSubmitted ? "Enter your Email OTP" : "Enter your Hinge OTP"}
          variant="outlined"
          size="small"
          fullWidth
          value={phoneNumberOtpSubmitted ? emailOtp : phoneNumberOtp}
          onChange={this.handleChange}
          type="text"
          disabled={!this.state.isEditable}
          slotProps={textFieldSlotProps(isValid)}
          sx={textFieldSx(isValid)}
        />
        </div>
        <div style={fadeInStyle}>
        <Button
          variant="contained"
          sx={{
            ...buttonSx,
            color: loading ? 'white' : 'white', // Explicitly set color for loading text
          }}
          onClick={this.handleOtpSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <span style={{ color: 'white', marginRight: '8px' }}>Submitting</span>
              <CircularProgress size={24} color="inherit" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius: '80%' }} />
            </>
          ) : (
            "Submit"
          )}
        </Button>
        </div>
        {errorMessage ? (
          <div style={{ ...fadeInStyle, fontSize: '12px', color: 'red', marginTop: '10px' }}>
            {errorMessage}
          </div>
        ) : (
          this.state.countdown > 0 && (
            <div style={{ ...fadeInStyle, fontSize: '12px', color: 'white', marginTop: '10px' }}>
              OTP expires in {this.state.countdown} seconds
            </div>
          )
        )}
        <div style={{ ...fadeInStyle, fontSize: '12px', color: 'white', marginTop: '10px', textAlign: 'center' }}>
          <Button
            sx={{ color: 'white', textTransform: 'none', fontSize: '12px', padding: 0, minWidth: 'auto' }}
            onClick={() => window.location.href = '/areyouready'}
          >
            Didn’t Get Code, Try Again?
          </Button>
        </div>

      </div>
    );
  }
}

// Map the Redux state to props
const mapStateToProps = (state) => ({
  email: state.user.userData.email, // Assumes userData contains the email in Redux state
  subscription: state.user.userData.subscription, // Assumes subscription is stored in Redux state
});

// Map the dispatch actions to props
const mapDispatchToProps = {
  setUserData,
};

// Use connect to bind both state and dispatch
export default connect(mapStateToProps, mapDispatchToProps)(HingeOtpInput);
