import React, { Component } from 'react';
import backgroundImage from './assets/websitebackground.jpg'; // Adjust the path if necessary

class WebsiteContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 1, // Start with full opacity
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ opacity: 0.95 });
    }, 1); 
  }

  render() {
    const containerStyle = {
      position: 'relative',
      minHeight: '100vh', // Use minHeight instead of height for better content handling
      width: '100vw',
      display: 'flex',
      flexDirection: 'column', // Flex column for vertical alignment of content
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto', // Allow for scrolling if content overflows
    };

    const backgroundStyle = {
      position: 'fixed', // Use fixed to keep the background in place during scrolling
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      opacity: this.state.opacity, // Bind opacity to state
      filter: 'brightness(80%)', // Reduce brightness to 40%
      transition: 'opacity 1s ease-in-out', // Add transition for fade effect
      zIndex: -1, // Ensure it stays behind the content
    };

    const contentStyle = {
      position: 'relative',
      zIndex: 1, // Ensure content stays above the background
      padding: '20px', // Optional padding to give content some space from the edges
    };

    return (
      <div style={containerStyle}>
        <div style={backgroundStyle}></div>
        <div style={contentStyle}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default WebsiteContainer;
